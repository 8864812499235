import { __extends, __rest, __spreadArrays } from "tslib";
import { isString } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createRicosTheme, getCssOverride } from '../../services/themeRicos';
import getStyle from '../../services/get-style';
import { viewerCustomStyleFn, viewerCustomBackgroundStyleFn, styleSelectionPredicate, getTextColorSchema, } from '../../services/text-color-utils';
import { createHashtagPath, createHashtagHref } from '../../../services/search-page-url';
import linkSettings from '../../constants/linkSettings';
import { isIos } from '../../../services/detect-platform';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../../constants/messages';
import styles from './ricos-viewer-wrapper.scss';
var MAX_PINTEREST_IMAGE_SIZE = 5000;
var RicosViewer = /** @class */ (function (_super) {
    __extends(RicosViewer, _super);
    function RicosViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: false,
        };
        _this.getImagePluginConfig = function () {
            return {
                imageProps: function (src) { return ({
                    'data-pin-url': _this.props.pageUrl,
                    'data-pin-media': isString(src)
                        ? src
                        : _this.props.getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
                }); },
            };
        };
        _this.getGalleryPluginConfig = function () {
            var _a = _this.props, isOOI = _a.isOOI, isInEditor = _a.isInEditor, isInPreview = _a.isInPreview, Wix = _a.Wix, isExperimentProdOOIEditorEnabled = _a.isExperimentProdOOIEditorEnabled;
            var useCustomScroll = !isOOI || (isOOI && (isInEditor || (isInPreview && isExperimentProdOOIEditorEnabled)));
            var galleryScrollingElement = function () { return ({
                addEventListener: function (eventName, callback) {
                    Wix.addEventListener(eventName.toUpperCase(), callback);
                },
                removeEventListener: function (eventName, callback) {
                    Wix.removeEventListener(eventName.toUpperCase(), callback);
                },
            }); };
            return {
                imageProps: function () { return ({ 'data-pin-url': _this.props.pageUrl }); },
                scrollingElement: useCustomScroll ? galleryScrollingElement : typeof window !== 'undefined' && window,
            };
        };
        _this.getTextColorPluginConfig = function () {
            var _a = _this.props, appSettings = _a.appSettings, isHexColor = _a.isHexColor;
            var colorScheme = getTextColorSchema(appSettings);
            return {
                customStyleFn: viewerCustomStyleFn(colorScheme, { isHexColor: isHexColor }),
                styleSelectionPredicate: styleSelectionPredicate(colorScheme, { isHexColor: isHexColor }),
            };
        };
        _this.getTextHighlightPluginConfig = function () {
            var _a = _this.props, appSettings = _a.appSettings, isHexColor = _a.isHexColor;
            var colorScheme = getTextColorSchema(appSettings);
            return {
                customStyleFn: viewerCustomBackgroundStyleFn(colorScheme, { isHexColor: isHexColor }),
                styleSelectionPredicate: styleSelectionPredicate(colorScheme, { isHexColor: isHexColor }),
            };
        };
        _this.getLinkPreviewPluginConfig = function () {
            return {
                enableEmbed: true,
                enableLinkPreview: false,
            };
        };
        _this.getHashtagPluginConfig = function () {
            var _a = _this.props, hashtagNavigate = _a.hashtagNavigate, sectionUrl = _a.sectionUrl;
            return {
                onClick: function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    hashtagNavigate(createHashtagPath(event.target.innerText, {
                        newPath: _this.props.isHashtagPageAsFeedPageEnabled,
                    }));
                },
                createHref: function (decoratedText) {
                    return createHashtagHref(decoratedText, sectionUrl, {
                        newPath: _this.props.isHashtagPageAsFeedPageEnabled,
                    });
                },
            };
        };
        _this.getActionButtonPluginConfig = function () { return ({ onClick: _this.props.onButtonClick }); };
        _this.getFilePluginConfig = function () {
            var _a = _this.props, postId = _a.postId, actions = _a.actions, bi = _a.bi, isDemoPost = _a.isDemoPost;
            return {
                resolveFileUrl: function (_a) {
                    var config = _a.config, file = __rest(_a, ["config"]);
                    isIos() && actions.showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
                    var getBiProps = function (success) { return ({
                        file_ext: file.type,
                        file_id: file.id,
                        file_size: file.size,
                        mime_type: file.mimeType,
                        post_id: postId,
                        is_demo: isDemoPost,
                        origin: 'post',
                        success: success,
                    }); };
                    return actions
                        .requestFileDownloadUrl(postId, file.path, file.id, file.mimeType)
                        .then(function (_a) {
                        var url = _a.url;
                        bi.fileDownloaded(getBiProps(true));
                        return url;
                    })
                        .catch(function () { return bi.fileDownloaded(getBiProps(false)); });
                },
            };
        };
        _this.getPlugins = function () {
            var plugins = __spreadArrays([
                _this.props.pluginCodeBlock(),
                _this.props.pluginDivider(),
                _this.props.pluginLinkButton(),
                _this.props.pluginGallery(_this.getGalleryPluginConfig()),
                _this.props.pluginGiphy(),
                _this.props.pluginHashtag(_this.getHashtagPluginConfig()),
                _this.props.pluginHtml(),
                _this.props.pluginImage(_this.getImagePluginConfig()),
                _this.props.pluginLineSpacing(),
                _this.props.pluginIndent(),
                _this.props.pluginLink()
            ], (_this.props.isRicosEmbedButtonsEnabled
                ? [_this.props.pluginLinkPreview(_this.getLinkPreviewPluginConfig())]
                : []), [
                _this.props.pluginTextColor(_this.getTextColorPluginConfig()),
                _this.props.pluginTextHighlight(_this.getTextHighlightPluginConfig()),
                _this.props.pluginVideo(),
                _this.props.pluginFileUpload(_this.getFilePluginConfig()),
                _this.props.pluginHeadersMarkdown(),
                _this.props.pluginActionButton(_this.getActionButtonPluginConfig()),
                _this.props.pluginVerticalEmbed(),
                _this.props.pluginTable(),
                _this.props.pluginCollapsibleList(),
            ]);
            return plugins;
        };
        _this.onError = function (error) {
            if (!_this.state.error) {
                _this.setState({ error: error });
            }
        };
        // TODO: remove when ricos supports plain object
        _this.wixExperiments = {
            all: function () { return props.experiments; },
        };
        return _this;
    }
    RicosViewer.prototype.render = function () {
        if (this.state.error) {
            return this.props.fallback();
        }
        var _a = this.props, WixRicosViewer = _a.WixRicosViewer, content = _a.content, rceVersion = _a.rceVersion, getTheme = _a.getTheme, _getStyle = _a.getStyle, isCompact = _a.isCompact, appSettings = _a.appSettings, section = _a.section, isBusinessManager = _a.isBusinessManager, isSeo = _a.isSeo, isOOI = _a.isOOI, isMobile = _a.isMobile, locale = _a.locale, isPostPageBundle = _a.isPostPageBundle, instance = _a.instance, postId = _a.postId, disableTheme = _a.disableTheme, parentClass = _a.parentClass, setViewerRef = _a.setViewerRef, iframeSandboxDomain = _a.iframeSandboxDomain, shouldApplyPostDesignInFeed = _a.shouldApplyPostDesignInFeed;
        var isPostPageEnabled = true;
        var readOnly = true;
        var isViewer = true;
        var isPostPage = isPostPageBundle;
        var plugins = this.getPlugins();
        var theme = disableTheme
            ? createRicosTheme({ appSettings: appSettings, isPostPage: isPostPage, isMobile: isMobile, section: section, isPostPageEnabled: isPostPageEnabled, parentClass: parentClass, shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed })
            : {};
        var cssOverride = disableTheme
            ? getCssOverride({ appSettings: appSettings, isViewer: isViewer, version: rceVersion, isOOI: isOOI, isCompact: isCompact, readOnly: readOnly, isBusinessManager: isBusinessManager })
            : getTheme();
        var style = _getStyle(appSettings, { isPostPageEnabled: isPostPageEnabled });
        var className = classNames(styles.container, cssOverride.overrideDefaultTheme, cssOverride.overrideAppSettings);
        return (React.createElement("div", { className: styles.relativeContainer },
            React.createElement("div", { style: style, ref: setViewerRef, className: className, "data-rce-version": rceVersion },
                React.createElement(WixRicosViewer, { plugins: plugins, content: content, cssOverride: cssOverride, theme: theme, locale: locale, instance: instance, onError: this.onError, isMobile: isMobile, seoSettings: isSeo, linkSettings: linkSettings, wixExperiments: this.wixExperiments, addAnchors: true, iframeSandboxDomain: iframeSandboxDomain, biSettings: {
                        consumer: 'Blog',
                        contentId: postId,
                        platform: isBusinessManager ? 'BM' : 'Livesite',
                        usage: isCompact ? 'Comment' : isPostPage ? 'Post' : section,
                    } }))));
    };
    return RicosViewer;
}(React.PureComponent));
RicosViewer.propTypes = {
    WixRicosViewer: PropTypes.elementType.isRequired,
    pluginCodeBlock: PropTypes.func.isRequired,
    pluginDivider: PropTypes.func.isRequired,
    pluginLinkButton: PropTypes.func.isRequired,
    pluginGallery: PropTypes.func.isRequired,
    pluginGiphy: PropTypes.func.isRequired,
    pluginHashtag: PropTypes.func.isRequired,
    pluginHtml: PropTypes.func.isRequired,
    pluginImage: PropTypes.func.isRequired,
    pluginLineSpacing: PropTypes.func.isRequired,
    pluginIndent: PropTypes.func.isRequired,
    pluginLink: PropTypes.func.isRequired,
    pluginLinkPreview: PropTypes.func.isRequired,
    pluginTextColor: PropTypes.func.isRequired,
    pluginTextHighlight: PropTypes.func.isRequired,
    pluginVideo: PropTypes.func.isRequired,
    pluginFileUpload: PropTypes.func.isRequired,
    pluginHeadersMarkdown: PropTypes.func.isRequired,
    pluginActionButton: PropTypes.func.isRequired,
    pluginVerticalEmbed: PropTypes.func.isRequired,
    pluginTable: PropTypes.func.isRequired,
    pluginCollapsibleList: PropTypes.func.isRequired,
    postId: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    rceVersion: PropTypes.string.isRequired,
    parentClass: PropTypes.string,
    getTheme: PropTypes.func.isRequired,
    getStyle: PropTypes.func.isRequired,
    isBusinessManager: PropTypes.bool,
    isCompact: PropTypes.bool.isRequired,
    appSettings: PropTypes.object.isRequired,
    isSeo: PropTypes.bool.isRequired,
    isInEditor: PropTypes.bool,
    isInPreview: PropTypes.bool,
    isOOI: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    isPostPageBundle: PropTypes.bool.isRequired,
    instance: PropTypes.string.isRequired,
    getImageUrl: PropTypes.func.isRequired,
    pageUrl: PropTypes.string.isRequired,
    isHexColor: PropTypes.func.isRequired,
    hashtagNavigate: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
    sectionUrl: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func,
    disableTheme: PropTypes.bool,
    isHashtagPageAsFeedPageEnabled: PropTypes.bool,
    isRicosEmbedButtonsEnabled: PropTypes.bool,
    Wix: PropTypes.object.isRequired,
    experiments: PropTypes.object.isRequired,
    setViewerRef: PropTypes.func.isRequired,
    isDemoPost: PropTypes.bool,
    shouldApplyPostDesignInFeed: PropTypes.bool,
};
RicosViewer.defaultProps = {
    isCompact: false,
    isBusinessManager: false,
    fallback: function () { return 'Content cannot be displayed'; },
    getStyle: getStyle,
};
export default RicosViewer;
